import { useCallback } from 'react';
import isEmpty from 'utilities/isEmpty';

export function Input(props) {
  const { blank, success, error, className, type, onChange, value, positiveOnly, ...rest } = props;

  const onlyNumbersRegExp = /^[0-9]*$/;
  const onlyPositiveNumbersRegExp = /^[1-9]*$/;

  const getNumberRegex = () => {
    if (positiveOnly) {
      return onlyPositiveNumbersRegExp;
    }

    return onlyNumbersRegExp;
  }

  const getSuccessErrorColor = () => {
    if (success != null && success) {
      return 'border-green-500 focus:border-green-500';
    } else if (error != null && error) {
      return 'placeholder-red-500 border-red-500 focus:border-red-500';
    } else {
      return 'placeholder-blue-gray-400 !border-neutral-400 focus:border-green-500';
    }
  };

  const isNumber = useCallback(() => {
    return !isEmpty(type) && type === "number";
  }, [type])

  const handleOnChange = (e) => {
    if (isNumber()) {
      var numberRegex = getNumberRegex();
      if (numberRegex.test(e.target.value)) {
        if (!isEmpty(onChange))
          onChange(e);
      }

    }
    else {
      if (!isEmpty(onChange))
        onChange(e);
    }
  }

  const handleOnKeyDown = (e) => {
    if (isNumber() && (e.keyCode === 110 || e.keyCode === 188 || e.keyCode === 190 || e.keyCode === 189 || e.keyCode === 32)) {
      e.preventDefault()
    }
  }

  return (
    <input
      onChange={handleOnChange}
      onKeyDown={handleOnKeyDown}
      value={value === null ? '' : value}
      type={!isEmpty(type) ? type : 'text'}
      pattern={isNumber() ? "\\d*" : null}
      inputMode={isNumber() ? "numeric" : null}
      className={!blank ? ` peer w-full 
      h-12
          font-sans font-normal outline outline-0 
        focus:outline-0 disabled:bg-base-100 disabled:border-0 disabled:text-base-content transition-all 
        border focus:border-1 
        text-base px-3 py-3 rounded-md !pr-3 
        bg-base-100 text-base-content/80 
        hover:bg-neutral-10 
        focus:bg-neutral-10 
        ${getSuccessErrorColor()} ${!isEmpty(className) ? className : ''}` : className}
      {...rest}
    />
  );
}


