"use client"
import { Input } from "components/controls/Input";
import { HomeAddressPicker } from "components/controls/search/HomeAddressPicker";
import { HomeEventTypePicker } from "components/controls/search/HomeEventTypePicker";
import { useMemo, useState } from "react";
import { MdOutlineSearch, MdOutlinePeopleAlt, MdOutlineLocationOn } from "react-icons/md";
import isEmpty from "utilities/isEmpty";
import SearchBuilder from "app/search/(utils)/SearchBuilder";
import { useNavigate } from "hooks/useNavigate";
import { useGetHomeDataQuery } from "lib/services/home";
import Paths from "lib/routes/Paths";

export default function HomeSearch({ isPlaceholder }) {
    const { data, isLoading, isFetching } = useGetHomeDataQuery([]);
    const navigate = useNavigate();

    const [eventType, setEventType] = useState(null);
    const [people, setPeople] = useState(null);
    const [address, setAddress] = useState(null);


    const handleGuestsOnChange = (e) => {
        setPeople(!isEmpty(e.target.value) ? e.target.value : null);
    }

    const handleSearch = (e) => {
        return navigate(SearchBuilder.GenerateFullPath(Paths.SEARCH.ROOT, { eventTypeId: eventType?.id, eventTypeName: eventType?.name, people: people, locationId: address?.id, locationSourceId: address?.locationSourceId, locationName: address?.locationName }));
    }

    const notReady = useMemo(() => {
        return isEmpty(data) || isLoading;
    }, [data, isLoading]);

    // const testFunction1 = useAuthWallFunction(() => alertSuccess("Success!"));

    return (<div className={`grid grid-cols-11 bg-base-100 px-4 py-2 lg:py-4 rounded-lg shadow-md lg:space-x-3 ${notReady || isPlaceholder ? "shimmer" : ""}`}>
        <div className={`col-span-12 lg:col-span-3 flex items-center border-b lg:border-b-0 lg:border-r border-base-content/20 py-3 lg:py-0 ${notReady || isPlaceholder ? "invisible" : ""}`}>
            <div className="mr-3 ">
                <MdOutlineSearch className="h-8 w-8 text-base-content/50" />
            </div>
            <div className=" w-full pb-1 lg:pb-2 pr-3">
                <div className="text-sm font-medium uppercase">Event type</div>
                <div className=""> <HomeEventTypePicker
                    eventTypes={data?.eventTypes}
                    className="w-full"
                    onChange={(value) => setEventType(value?.value != null ? { id: value?.value, name: value?.label } : null)}
                    name={"eventType"}
                    placeholder={"What are you planning?"}
                /></div>
            </div>
        </div>
        <div className={`col-span-12 lg:col-span-3 flex items-center border-b lg:border-b-0 lg:border-r border-base-content/20 py-3 lg:py-0 ${notReady || isPlaceholder ? "invisible" : ""}`}>
            <div className="mr-3 flex items-center">
                <MdOutlinePeopleAlt className="h-8 w-8 text-base-content/50" />
            </div>
            <div className=" w-full pb-1 lg:pb-2 pr-3">
                <div className="text-sm font-medium uppercase">Guests</div>
                <div> <label htmlFor="Number of guests"><Input
                    aria-labelledby="Number of guests"
                    blank={true}
                    className={`w-full peer focus:outline-none  focus:outline-0 focus:border-0 bg-base-100 placeholder-base-content/60 text-base-content text-xl xl:text-2xl h-[32px]`}
                    placeholder="Number of guests"
                    onChange={handleGuestsOnChange}
                    value={people}
                    type="number"
                    step="5"
                    min="0"
                    max="5000"
                /></label></div>
            </div>
        </div>
        <div className={`col-span-12 lg:col-span-3 flex items-center py-3 lg:py-0 ${notReady || isPlaceholder ? "invisible" : ""}`}>
            <div className="mr-3 flex items-center">
                <MdOutlineLocationOn className="h-8 w-8 text-base-content/50" />
            </div>
            <div className=" w-full pb-1 lg:pb-2 pr-3">
                <div className="text-sm font-medium uppercase">Location</div>
                <div><HomeAddressPicker
                    className="w-full"
                    homeLocations={data?.homeLocations}
                    onChange={(value) => setAddress(value?.value)}
                    name={"address"}
                    placeholder={"Event location"}
                /></div>
            </div>
        </div>
        <div className={`col-span-12 lg:col-span-2 flex items-center pb-3 lg:py-0 ${notReady || isPlaceholder ? "invisible" : ""}`}>
            <button type="button" onClick={handleSearch} className="btn btn-block btn-lg btn-success">Search</button>
        </div>
        {/* <button
            onClick={() => {
                testFunction1();
            }}>
            Test 1
        </button> */}
    </div>);
}