import qs from 'qs';
import Paths from '../../../lib/routes/Paths';
import { SearchLocationSourceTypes } from 'utilities/constants';

class SearchBuilder {

    static ClearMoreFilters(search) {
        var queryObject = qs.parse(search, { ignoreQueryPrefix: true });

        queryObject.layoutTypeId = null;
        queryObject.featureIds = null;
        queryObject.menuOfferIds = null;
        queryObject.venueTypeIds = null;
        queryObject.areaTypeIds = null;
        queryObject.hasNoAgeRestriction = null;
        queryObject.hasCancellationFlexibility = null;
        queryObject.isSuperVenue = null;

        return this.GenerateFullPath(Paths.SEARCH.ROOT, queryObject);
    }

    static IsSuperVenueChange(search, isSuperVenue) {
        var queryObject = qs.parse(search, { ignoreQueryPrefix: true });

        queryObject.isSuperVenue = isSuperVenue;

        return this.GenerateFullPath(Paths.SEARCH.ROOT, queryObject);
    }

    static HasCancellationFlexibilityChange(search, hasCancellationFlexibility) {
        var queryObject = qs.parse(search, { ignoreQueryPrefix: true });

        queryObject.hasCancellationFlexibility = hasCancellationFlexibility;

        return this.GenerateFullPath(Paths.SEARCH.ROOT, queryObject);
    }

    static HasNoAgeRestrictionChange(search, hasNoAgeRestriction) {
        var queryObject = qs.parse(search, { ignoreQueryPrefix: true });

        queryObject.hasNoAgeRestriction = hasNoAgeRestriction;

        return this.GenerateFullPath(Paths.SEARCH.ROOT, queryObject);
    }

    static ChangeAreaTypes(search, areaTypeIds) {
        var queryObject = qs.parse(search, { ignoreQueryPrefix: true });

        queryObject.areaTypeIds = areaTypeIds;

        return this.GenerateFullPath(Paths.SEARCH.ROOT, queryObject);
    }

    static ToggleVenueTypes(search, venueTypeIds) {
        var queryObject = qs.parse(search, { ignoreQueryPrefix: true });

        queryObject.venueTypeIds = venueTypeIds;

        return this.GenerateFullPath(Paths.SEARCH.ROOT, queryObject);
    }

    static ToggleMenuOffers(search, menuOfferIds) {
        var queryObject = qs.parse(search, { ignoreQueryPrefix: true });

        queryObject.menuOfferIds = menuOfferIds;

        return this.GenerateFullPath(Paths.SEARCH.ROOT, queryObject);
    }

    static FeaturesChange(search, featureIds) {
        var queryObject = qs.parse(search, { ignoreQueryPrefix: true });

        queryObject.featureIds = featureIds;

        return this.GenerateFullPath(Paths.SEARCH.ROOT, queryObject);
    }

    static SetPriceValues(search, minPrice, maxPrice) {
        var queryObject = qs.parse(search, { ignoreQueryPrefix: true });

        queryObject.minPrice = minPrice;
        queryObject.maxPrice = maxPrice;

        return this.GenerateFullPath(Paths.SEARCH.ROOT, queryObject);
    }

    static SetMinPrice(search, minPrice) {
        var queryObject = qs.parse(search, { ignoreQueryPrefix: true });

        queryObject.minPrice = minPrice;

        return this.GenerateFullPath(Paths.SEARCH.ROOT, queryObject);
    }

    static SetMaxPrice(search, maxPrice) {
        var queryObject = qs.parse(search, { ignoreQueryPrefix: true });

        queryObject.maxPrice = maxPrice;

        return this.GenerateFullPath(Paths.SEARCH.ROOT, queryObject);
    }

    static ClearPriceFilter(search) {
        var queryObject = qs.parse(search, { ignoreQueryPrefix: true });

        queryObject.minPrice = null;
        queryObject.maxPrice = null;

        return this.GenerateFullPath(Paths.SEARCH.ROOT, queryObject);
    }

    static LayoutTypeChange(search, layoutTypeId) {
        var queryObject = qs.parse(search, { ignoreQueryPrefix: true });

        queryObject.layoutTypeId = layoutTypeId;

        return this.GenerateFullPath(Paths.SEARCH.ROOT, queryObject);
    }

    static PeopleCountChange(search, people) {
        var queryObject = qs.parse(search, { ignoreQueryPrefix: true });

        queryObject.people = people;

        return this.GenerateFullPath(Paths.SEARCH.ROOT, queryObject);
    }


    static ClearPeopleFilter(search) {
        var queryObject = qs.parse(search, { ignoreQueryPrefix: true });

        queryObject.layoutTypeId = null;
        queryObject.people = null;

        return this.GenerateFullPath(Paths.SEARCH.ROOT, queryObject);
    }

    static EventTypeChange(search, { eventTypeId, eventTypeName }) {
        var queryObject = qs.parse(search, { ignoreQueryPrefix: true });

        queryObject.eventTypeId = eventTypeId;
        queryObject.eventTypeName = eventTypeName;

        return this.GenerateFullPath(Paths.SEARCH.ROOT, queryObject);
    }

    static PageChange(search, { page }) {
        var queryObject = qs.parse(search, { ignoreQueryPrefix: true });

        queryObject.page = page;

        return this.GenerateFullPath(Paths.SEARCH.ROOT, queryObject);
    }

    static LocationMapBoundsChange(search, { topLeftLat, topLeftLng, bottomRightLat, bottomRightLng }) {
        var queryObject = qs.parse(search, { ignoreQueryPrefix: true });
        queryObject.locationId = null;
        queryObject.locationName = null;
        queryObject.locationSourceId = SearchLocationSourceTypes.MAP;
        queryObject.topLeftLat = topLeftLat;
        queryObject.topLeftLng = topLeftLng;
        queryObject.bottomRightLat = bottomRightLat;
        queryObject.bottomRightLng = bottomRightLng;

        return this.GenerateFullPath(Paths.SEARCH.ROOT, queryObject);
    }

    static LocationChange(search, { locationId, locationSourceId, locationName }) {
        var queryObject = qs.parse(search, { ignoreQueryPrefix: true });

        queryObject.topLeftLat = null;
        queryObject.topLeftLng = null;
        queryObject.bottomRightLat = null;
        queryObject.bottomRightLng = null;

        queryObject.locationId = locationId;
        queryObject.locationSourceId = locationSourceId;
        queryObject.locationName = locationName;


        return this.GenerateFullPath(Paths.SEARCH.ROOT, queryObject);
    }

    static ClearLocation(search) {
        var queryObject = qs.parse(search, { ignoreQueryPrefix: true });

        queryObject.topLeftLat = null;
        queryObject.topLeftLng = null;
        queryObject.bottomRightLat = null;
        queryObject.bottomRightLng = null;

        queryObject.locationId = null;
        queryObject.locationSourceId = null;
        queryObject.locationName = null;


        return this.GenerateFullPath(Paths.SEARCH.ROOT, queryObject);
    }

    static ClearAllFilters(search) {
        var queryObject = qs.parse(search, { ignoreQueryPrefix: true });

        var newQueryObject = {};

        //we preserve location data
        newQueryObject.topLeftLat = queryObject.topLeftLat;
        newQueryObject.topLeftLng = queryObject.topLeftLng;
        newQueryObject.bottomRightLat = queryObject.bottomRightLat;
        newQueryObject.bottomRightLng = queryObject.bottomRightLng;

        newQueryObject.locationId = queryObject.locationId;
        newQueryObject.locationSourceId = queryObject.locationSourceId;
        newQueryObject.locationName = queryObject.locationName;

        return this.GenerateFullPath(Paths.SEARCH.ROOT, newQueryObject);
    }


    static GenerateFullPath(urlOrPath, { eventTypeId, eventTypeName, people, locationId, locationSourceId, locationName, topLeftLat, topLeftLng, bottomRightLat, bottomRightLng, page, layoutTypeId, minPrice, maxPrice, featureIds, menuOfferIds, venueTypeIds, areaTypeIds, hasNoAgeRestriction, isSuperVenue, hasCancellationFlexibility }) {
        const search = {
            eventTypeId: eventTypeId,
            eventTypeName: eventTypeName,
            people: people,
            locationId: locationId,
            locationSourceId: locationSourceId,
            locationName: locationName,
            topLeftLat: topLeftLat,
            topLeftLng: topLeftLng,
            bottomRightLat: bottomRightLat,
            bottomRightLng: bottomRightLng,
            page: page,
            layoutTypeId: layoutTypeId,
            people: people,
            minPrice: minPrice,
            maxPrice: maxPrice,
            featureIds: featureIds,
            menuOfferIds: menuOfferIds,
            venueTypeIds: venueTypeIds,
            areaTypeIds: areaTypeIds,
            hasNoAgeRestriction: hasNoAgeRestriction,
            isSuperVenue: isSuperVenue,
            hasCancellationFlexibility: hasCancellationFlexibility
        };

        const queryString = qs.stringify(search, { allowEmptyArrays: true, encodeValuesOnly: true, skipNulls: true });

        if (queryString) {
            return `${urlOrPath}?${queryString}`;
        }
        return urlOrPath;
    }
}

export default SearchBuilder;