import { getConfig } from "utilities/config";

import isEmpty from "utilities/isEmpty";
import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import _debounce from 'lodash/debounce';
import { BaseSelectFromApi } from "../select/base/SelectFromApi";
import { alertError } from "../functions/alerts";
import { getIconComponents } from "../select/constants/IconConstants";

export function HomeEventTypePicker(props) {
    const { onChange, value: valueProp, ...rest } = props;
    const config = getConfig();
    const [value, setValue] = useState(valueProp);
    const [loading, setLoading] = useState(null);

    const defaultOptions = useMemo(() => props.eventTypes?.map((eventType) => {
        return {
            value: eventType.id, label: eventType.metaName
        }
    }), [props.eventTypes]);


    const loadOptions = (inputValue, callback) => {
        setLoading(true)
        try {
            axios
                .get(
                    `${config.NEXT_PUBLIC_BASE_URL}/search/eventtypes?search==${inputValue}`,
                )
                .then(({ data }) => {
                    setLoading(false);
                    let options = data.map((prediction) => {
                        return {
                            value: prediction.id, label: prediction.metaName
                        }
                    });
                    callback(options);
                });
        } catch (error) {
            //todo: implement error handling here
            callback(null);
            setLoading(false);
        }

    };

    const handleChange = (value) => {
        if (!isEmpty(value?.value)) {
            setValue(value);
            onChange(value);
        }
        else {
            setValue(null);
            onChange(null);
        }
    }

    const wait = 500;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceLoadOptions = useCallback(_debounce(loadOptions, wait), []);

    const { ClearIndicator, MultiValueRemove } = getIconComponents();

    const getControlStyles = () => {
        return {
            base: `w-full peer focus:outline-0 focus:border-0 bg-base-100  text-base-content text-xl xl:text-2xl h-[32px]`,
            focus: `border-0 outline-0 `,
            nonFocus: `border-0 outline-0`,
        };
    }

    const getPlaceholderStyles = () => {
        return `w-full peer focus:outline-0 focus:border-0 bg-base-100 text-base-content/60 text-base-content text-xl xl:text-2xl`;
    }

    // export const selectInputStyles = "text-base-content/100 pl-2 py-0.5 ";
    const getSelectInputStyles = () => {
        return `text-base-content/100 text-ellipsis overflow-hidden`;
    }

    // export const valueContainerStyles = "p-1 gap-1";
    const getValueContainerStyles = () => {
        return `p-0 gap-0`;
    }

    // export const singleValueStyles = "ml-1 text-[0.875rem]";
    const getSingleValueStyles = () => {
        return `text-xl xl:text-2xl `;
    }

    return (
        <label htmlFor="Event type - What are you planning?"><BaseSelectFromApi
            aria-labelledby="Event type - What are you planning?"
            className={"text-xl "}
            isClearable={true}
            components={{
                DropdownIndicator: null,
                ClearIndicator,
                MultiValueRemove,
            }}
            controlStylesOverride={getControlStyles()}
            placeholderStylesOverride={getPlaceholderStyles()}
            selectInputStylesOverride={getSelectInputStyles()}
            valueContainerStylesOverride={getValueContainerStyles()}
            singleValueStylesOverride={getSingleValueStyles()}
            cacheOptions
            loadOptions={debounceLoadOptions}
            defaultOptions={defaultOptions}
            onChange={handleChange}
            value={value}
            noOptionsMessage={() => 'No event types found.'}
            isMulti={false}
            {...rest}
            isLoading={loading}

        /></label>

    );
}
