import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
;
import(/* webpackMode: "eager", webpackExports: ["SpaceCard"] */ "/vercel/path0/src/components/controls/cards/SpaceCard/SpaceCard.js");
;
import(/* webpackMode: "eager", webpackExports: ["FullFooter"] */ "/vercel/path0/src/components/footers/FullFooter.js");
;
import(/* webpackMode: "eager", webpackExports: ["NavbarDefault"] */ "/vercel/path0/src/components/navigation/NavbarDefault.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/sections/home/HomeSearch.js");
