import dynamic from 'next/dynamic'
import { DropdownIndicator, ClearIndicator, MultiValueRemove } from "../constants/IconConstants"
import {
  selectInputStyles, valueContainerStyles, singleValueStyles, multiValueStyles,
  multiValueLabelStyles, multiValueRemoveStyles, indicatorsContainerStyles,
  clearIndicatorStyles, indicatorSeparatorStyles, dropdownIndicatorStyles, menuStyles,
  groupHeadingStyles, optionStyles, noOptionsMessageStyles, getControlStyles, getPlaceholderStyles
} from "../constants/StyleConstants"
import clsx from "clsx";
import React, { useId, useMemo } from "react";
import { Input } from 'components/controls/Input';
import isEmpty from 'utilities/isEmpty';
import AsyncSelect from 'react-select/async';

export function BaseSelectFromApi(props) {
  const { success, error, isDisabled, controlStylesOverride, placeholderStylesOverride, selectInputStylesOverride, valueContainerStylesOverride, singleValueStylesOverride, ...rest } = props

  const successProp = success ?? false;
  const errorProp = error ?? false;
  const isDisabledProp = isDisabled ?? false;

  let controlStyles = !isEmpty(controlStylesOverride) ? controlStylesOverride : getControlStyles(successProp, errorProp, isDisabledProp);
  let placeholderStyles = !isEmpty(placeholderStylesOverride) ? placeholderStylesOverride : getPlaceholderStyles(successProp, errorProp);

  let selectInputStylesDynamic = !isEmpty(selectInputStylesOverride) ? selectInputStylesOverride : selectInputStyles;
  let valueContainerStylesDynamic = !isEmpty(valueContainerStylesOverride) ? valueContainerStylesOverride : valueContainerStyles;
  let singleValueStylesDynamic = !isEmpty(singleValueStylesOverride) ? singleValueStylesOverride : singleValueStyles;

  return (
    <AsyncSelect
      instanceId={useId()}

      isDisabled={isDisabledProp}
      menuPosition="fixed"
      menuPlacement="bottom"
      noOptionsMessage={() => 'No results founds...'}
      // isMulti
      // closeMenuOnSelect={false}
      hideSelectedOptions={true}
      unstyled
      styles={{
        input: (base) => ({
          ...base,
          "input:focus": {
            boxShadow: "none",
          },
        }),
        // On mobile, the label will truncate automatically, so we want to
        // override that behaviour.
        multiValueLabel: (base) => ({
          ...base,
          whiteSpace: "normal",
          overflow: "visible",
        }),
        control: (base) => ({
          ...base,
          transition: "none",
        }),
      }}
      components={{ DropdownIndicator, ClearIndicator, MultiValueRemove }}
      classNames={{
        control: ({ isFocused }) =>
          clsx(
            isFocused ? controlStyles.focus : controlStyles.nonFocus,
            controlStyles.base,
          ),
        placeholder: () => placeholderStyles,
        input: () => selectInputStylesDynamic,
        valueContainer: () => valueContainerStylesDynamic,
        singleValue: () => singleValueStylesDynamic,
        multiValue: () => multiValueStyles,
        multiValueLabel: () => multiValueLabelStyles,
        multiValueRemove: () => multiValueRemoveStyles,
        indicatorsContainer: () => indicatorsContainerStyles,
        clearIndicator: () => clearIndicatorStyles,
        indicatorSeparator: () => indicatorSeparatorStyles,
        dropdownIndicator: () => dropdownIndicatorStyles,
        menu: () => menuStyles,
        groupHeading: () => groupHeadingStyles,
        option: ({ isFocused, isSelected }) =>
          clsx(
            isFocused && optionStyles.focus,
            isSelected && optionStyles.selected,
            optionStyles.base,
          ),
        noOptionsMessage: () => noOptionsMessageStyles,
      }}
      {...rest}
    />

  )
}